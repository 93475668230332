const user_types_string = {
  creator: "creator",
  user: "user",
};

export const AUTOMATED_MESSAGES_LEARN_MORE_HREFS = {
  AUTOMATED_MESSAGES:
    "https://support.exlyapp.com/support/solutions/articles/84000347727-send-automated-messages-via-exly",
};

export default {
  creator_type: user_types_string.creator,
  page_title: "Automated Messages",
  creator_type_enum: 2,
  user_type: user_types_string.user,
  user_type_enum: 1,
  email: "email",
  sms: "sms",
  email_body: "email_body",
  edit_mode: "Edit",
  view: "View",
  purchase_confirmation: "Purchase Confirmation",
  reminders: "Reminders",
  updates: "Updates",
  certificate_drafts_type: 1,
  certificate_shared_type: 2,
  user_types_value: {
    creator: 2,
    user: 1,
  },
  view_type_enums: {
    initial: 0,
    view: 1,
    edit: 2,
  },

  error_message: "Something went wrong. Try Again",
  TABS: [
    {
      label: "Sent To Users",
      value: user_types_string.user,
    },
    {
      label: "Sent To Me",
      value: user_types_string.creator,
    },
  ],
};
