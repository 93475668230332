import api from "data/APIs";
import moment from "moment";
import dataProvider from "data/dataProvider";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { is_empty } from "utils/validations";
import constant from "../Customer/Constants/constant";
import {
  EXLY_COMMUNICATIONS__SET_USER_CATEGORY,
  EXLY_COMMUNICATIONS__REQUEST_USER_CATEGORY,
  EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS,
  EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
  EXLY_COMMUNICATIONS__REQUEST_CREATOR_CATEGORY,
  EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS,
  EXLY_COMMUNICATIONS_REQUEST_USER_CERTIFICATES,
  EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES,
  EXLY_COMMUNICATIONS_REQUEST_USER_LISTINGS,
  EXLY_COMMUNICATIONS_SET_USER_LISTINGS,
  EXLY_COMMUNICATIONS_REQUEST_LISTING_DETAILS,
  EXLY_COMMUNICATIONS_SET_LISTING_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_USER_DRAFTS_CERTIFICATES,
  EXLY_COMMUNICATIONS_REQUEST_USER_SHARED_CERTIFICATES,
} from "./actions";
import constants from "../../../../constants/constants";

function* getUserCategories({ payload }) {
  const { forceUpdate } = payload || {};
  try {
    const categories = yield select(
      (state) => state.communications.user_categories
    );
    if (is_empty(categories) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        api.get_user_comms_category
      );
      if (response.status === 200) {
        let output = {};
        for (let item of response.data) {
          output[item.key] = {
            key: item.key,
            heading: item.heading,
            sub_heading: item.sub_heading,
            comm_key: item.comm_key,
            list: [],
          };
        }
        yield put({
          type: EXLY_COMMUNICATIONS__SET_USER_CATEGORY,
          payload: output,
        });
      } else {
        throw `API: ${api.get_user_comms_category} FAIL`;
      }
    }
  } catch (error) {
    yield put({ type: EXLY_COMMUNICATIONS__SET_USER_CATEGORY, payload: null });
  } finally {
    console.log("done");
  }
}

function* getCreatorCategories({ payload }) {
  const { forceUpdate } = payload || {};
  try {
    const categories = yield select(
      (state) => state.communications.creator_categories
    );
    if (is_empty(categories) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        api.get_creator_comms_category
      );
      if (response.status === 200) {
        let output = {};
        for (let item of response.data) {
          output[item.key] = {
            key: item.key,
            heading: item.heading,
            sub_heading: item.sub_heading,
            comm_key: item.comm_key,
            list: [],
          };
        }
        yield put({
          type: EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
          payload: output,
        });
      } else {
        throw `API: ${api.get_creator_comms_category} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
      payload: null,
    });
  } finally {
    console.log("done");
  }
}

function* getCommunications(type) {
  try {
    const categories = yield select((state) =>
      type === constant.user_type
        ? state.communications.user_categories
        : state.communications.creator_categories
    );
    const key = yield select((state) => state.communications.selected_key);

    let api_resource =
      type === constant.user_type ? api.get_user_comms : api.get_creator_comms;
    if (is_empty(categories[key]?.list) && !is_empty(key)) {
      const response = yield call(
        dataProvider.custom_request,
        api_resource,
        "GET",
        { category_type: key }
      );
      if (response.status === 200) {
        categories[key].list = response.data.comms;
        yield put({
          type:
            type === constant.user_type
              ? EXLY_COMMUNICATIONS__SET_USER_CATEGORY
              : EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
          payload: { ...categories },
        });
      } else {
        throw `API: ${api_resource} FAIL`;
      }
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    console.log("done");
  }
}

function* getAutomatedMessageDetails(params) {
  try {
    const type = params.params.userType;
    const categories = yield select((state) =>
      type === constant.user_type
        ? state.communications.user_categories
        : state.communications.creator_categories
    );
    const key = yield select((state) => state.communications.selected_key);
    const msg_title = params.params.msg_title;
    const content_type = params.params.content_type;

    const indexOfList = categories[key].list.findIndex(
      (obj) => obj.title === msg_title
    );

    if (!is_empty(key)) {
      const response = yield call(
        dataProvider.custom_request,
        `notifications/exly/${msg_title}/details`,
        "GET"
      );
      if (response.status === 200) {
        categories[key].list[indexOfList].message_details = response?.data;
        categories[key].list[indexOfList].text_array =
          content_type === constant.email
            ? response?.data.email_body.split("$$")
            : response?.data.sms.split("$$");

        yield put({
          type:
            type === constant.user_type
              ? EXLY_COMMUNICATIONS__SET_USER_CATEGORY
              : EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
          payload: { ...categories },
        });
      } else {
        throw `API: FAIL`;
      }
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    console.log("done");
  }
}

function* getUserCertificates(certificate_status) {
  try {
    const page = yield select((state) => state.communications.page);
    let certificates = {};

    if (certificate_status === constant.certificate_drafts_type) {
      const drafts_data = yield call(
        dataProvider.custom_request,
        `${api.get_certificates}?certificate_status=1&page=${page}`,
        "GET"
      );

      if (drafts_data?.status === 200) {
        certificates.drafts = drafts_data?.data;
      }
    }

    if (certificate_status === constant.certificate_shared_type) {
      const shared_data = yield call(
        dataProvider.custom_request,
        `${api.get_certificates}?certificate_status=2&page=${page}`,
        "GET"
      );

      if (shared_data?.status === 200) {
        certificates.shared = shared_data?.data;
      }
    }

    yield put({
      type: EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES,
      payload: certificates,
    });
  } catch (err) {
    yield put({
      type: EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES,
      payload: null,
    });
  }
}

function* getUserCertificateListings() {
  try {
    //to fetch the one_on_one_class, group_class, workshop, no_schedule and content type listing for certificate
    const data = yield call(
      dataProvider.custom_request,
      `${api.fetch_offerings}`,
      "GET",
      {
        page: "all",
        type: `${constants?.scheduleTypesId?.one_on_one_class},${constants?.scheduleTypesId?.group_class},${constants?.scheduleTypesId?.workshop},${constants?.scheduleTypesId?.no_schedule},${constants?.scheduleTypesId?.content},${constants?.scheduleTypesId?.whatsapp}`,
      }
    );
    if (data.status === 200) {
      yield put({
        type: EXLY_COMMUNICATIONS_SET_USER_LISTINGS,
        payload: data.data.listings,
      });
    }
  } catch (err) {
    yield put({ type: EXLY_COMMUNICATIONS_SET_USER_LISTINGS, payload: null });
  }
}

function* getListingData() {
  try {
    const user_certificates = yield select(
      (state) => state.communications.certificateData
    );
    const data = yield call(
      dataProvider.custom_request,
      `${api.get_listing_details}/${user_certificates?.listing_uuid}`,
      "GET"
    );
    if (data.status === 200) {
      const output = {
        type: data?.data?.event?.type,
        end_date: data?.data?.event?.end_date || new Date(moment().toString()),
      };
      yield put({
        type: EXLY_COMMUNICATIONS_SET_LISTING_DETAILS,
        payload: output,
      });
    }
  } catch (err) {
    yield put({ type: EXLY_COMMUNICATIONS_SET_LISTING_DETAILS, payload: null });
  }
}

function* communicationSaga() {
  yield takeEvery(
    EXLY_COMMUNICATIONS__REQUEST_USER_CATEGORY,
    getUserCategories
  );
  yield takeEvery(
    EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS,
    getAutomatedMessageDetails
  );
  yield takeEvery(
    EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS,
    getAutomatedMessageDetails
  );
  yield takeEvery(
    EXLY_COMMUNICATIONS__REQUEST_CREATOR_CATEGORY,
    getCreatorCategories
  );
  yield takeEvery(EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS, () =>
    getCommunications(constant.user_type)
  );
  yield takeEvery(EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS, () =>
    getCommunications(constant.creator_type)
  );
  yield takeEvery(
    EXLY_COMMUNICATIONS_REQUEST_USER_CERTIFICATES,
    getUserCertificates
  );
  yield takeEvery(EXLY_COMMUNICATIONS_REQUEST_USER_DRAFTS_CERTIFICATES, () =>
    getUserCertificates(constant.certificate_drafts_type)
  );
  yield takeEvery(EXLY_COMMUNICATIONS_REQUEST_USER_SHARED_CERTIFICATES, () =>
    getUserCertificates(constant.certificate_shared_type)
  );
  yield takeEvery(
    EXLY_COMMUNICATIONS_REQUEST_USER_LISTINGS,
    getUserCertificateListings
  );
  yield takeEvery(EXLY_COMMUNICATIONS_REQUEST_LISTING_DETAILS, getListingData);
}

export default communicationSaga;
