// export const EXLY_DASHBOARD__SET_LOADER = '@EXLY_DASHBOARD/SET_LOADER';
export const EXLY_COMMUNICATIONS__REQUEST_CREATOR_CATEGORY =
  "@EXLY_COMMUNICATIONS/REQUEST_CREATOR_CATEGORY";
export const EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY =
  "@EXLY_COMMUNICATIONS/SET_CREATOR_CATEGORY";
export const EXLY_COMMUNICATIONS__REQUEST_USER_CATEGORY =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_CATEGORY";
export const EXLY_COMMUNICATIONS__SET_USER_CATEGORY =
  "@EXLY_COMMUNICATIONS/SET_USER_CATEGORY";
export const EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS =
  "@EXLY_COMMUNICATIONS/SET_USER_COMMUNICATIONS";
export const EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS =
  "@EXLY_COMMUNICATIONS/SET_CREATOR_COMMUNICATIONS";
export const EXLY_COMMUNICATIONS_SET_CERTIFICATE_FORMVALUES =
  "@EXLY_COMMUNICATIONS/SET_CERTIFICATE_FORMVALUES";
export const EXLY_COMMUNICATIONS_SET_CERTIFICATE_LISTING_TITLE =
  "@EXLY_COMMUNICATIONS/SET_CERTIFICATE_LISTING_TITLE";
export const EXLY_COMMUNICATIONS_REQUEST_USER_CERTIFICATES =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_CERTIFICATES";
export const EXLY_COMMUNICATIONS_REQUEST_USER_DRAFTS_CERTIFICATES =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_DRAFTS_CERTIFICATES";
export const EXLY_COMMUNICATIONS_REQUEST_USER_SHARED_CERTIFICATES =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_SHARED_CERTIFICATES";
export const EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES =
  "@EXLY_COMMUNICATIONS/SET_USER_CERTIFICATES";
export const EXLY_COMMUNICATIONS_REQUEST_USER_LISTINGS =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_LISTINGS";
export const EXLY_COMMUNICATIONS_SET_USER_LISTINGS =
  "@EXLY_COMMUNICATIONS/SET_USER_LISTINGS";
export const EXLY_COMMUNICATIONS_REQUEST_LISTING_DETAILS =
  "@EXLY_COMMUNICATIONS/REQUEST_LISTING_DETAILS";
export const EXLY_COMMUNICATIONS_SET_LISTING_DETAILS =
  "@EXLY_COMMUNICATIONS/SET_LISTING_DETAILS";
export const EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS =
  "@EXLY_COMMUNICATIONS/REQUEST_USER_MESSAGES_DETAILS";
export const EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS =
  "@EXLY_COMMUNICATIONS/REQUEST_CREATOR_MESSAGES_DETAILS";
