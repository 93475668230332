import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    classDetailsWrap: {
      fontSize: "14px",
      borderTop: ({ noBorder }) =>
        !noBorder && `1.5px solid ${theme.palette.secondary.shade_100}`,
      maxWidth: ({ fullWidth }) => (fullWidth ? "100%" : "260px"),
      "& $batch + $batch": {
        borderTop: `1.5px solid ${theme.palette.secondary.shade_100}`,
      },
    },
    batch: {
      padding: "8px 0 12px 0",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    batchRow: {
      padding: "3px 0 4px 0",
      display: "flex",
      flexDirection: "row",
      gap: "2px",
    },
    batchColumn: {
      padding: "8px 0 12px 0",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    session: {},
    workshopDetailsWrap: {
      maxWidth: ({ fullWidth }) => (fullWidth ? "100%" : "412px"),
    },
    flexGrow: {
      flexGrow: 1,
    },
    "@media (max-width:780px)": {
      workshopDetailsWrap: {
        maxWidth: "100%",
      },
    },
    "@media (max-width:390px)": {
      classDetailsWrap: {
        maxWidth: "100%",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
