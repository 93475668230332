import React from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./CustomerSuccessModal.styles";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { ExlyTextarea } from "common/form";
import { ReactComponent as TipsAndUpdatesIcon } from "assets/vectors/tips_and_updates.svg";
import { change_message_initial_state } from "../../constants";

function CustomerStatusModal({ open, onClose, onSubmit }) {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <>
      <Form
        initialValues={change_message_initial_state}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <ExlyModal
              title={"Customer Status"}
              header={"Customer Status"}
              open={open}
              onClose={onClose}
              onPrimaryBtnClick={() => {
                onSubmit(values);
              }}
              primaryBtnText={"Confirm"}
              modal_props={{
                modalPaperClassName: classes.modalPaperClass,
                backDropClickClose: true,
                primaryBtnFullWidth: false,
              }}
              mobile_modal_props={{
                keepMounted: true,
              }}
            >
              <div className={classes.modalBodyWrapper}>
                <div className={classes.websiteSuccessModalWrapper}>
                  <>
                    <Field
                      name="status_change_message"
                      component={ExlyTextarea}
                      label="Message to Customer (Optional)"
                      placeholder="Eg. The session has been marked complete."
                      minWidth={`${isDesktop ? "100%" : "98%"}`}
                      maxWidth="100%"
                      minHeight={`${isDesktop ? "100px" : "88px"}`}
                      customClasses={{ wrapper: classes.textArea }}
                    />
                    <p className={classes.primaryPara}>
                      <TipsAndUpdatesIcon />
                      An email will be sent to the customer
                    </p>
                  </>
                </div>
              </div>
            </ExlyModal>
          </form>
        )}
      </Form>
    </>
  );
}

export default React.memo(withComponentLibraryTheme(CustomerStatusModal));
