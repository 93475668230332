import React, { useCallback } from "react";
import moment from "moment";

import module_style from "../../../../Style.module.css";

import EmailIcon from "@material-ui/icons/MailOutline";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MoreVert from "@material-ui/icons/MoreVert";
import FileCopy from "@material-ui/icons/FileCopy";
import WhatsApp from "@material-ui/icons/WhatsApp";
import qs from "query-string";
import ScheduleNow from "assets/vectors/schedule_now.svg";
import { Button } from "@my-scoot/component-library-legacy";

import { is_empty } from "utils/validations";
import { DateConvert, parsePhoneNumber } from "utils/Utils";
import ExlyPopover from "common/Components/ExlyPopover/ExlyPopover";
import { useNotifications } from "utils/hooks";
import { orgPermissions } from "utils/OrgPermissions";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { EXLY_SCHEDULE__RESCHEDULE_SESSION } from "ui/pages/schedule/redux/actions";
import { customer_questions_constants } from "constants/customerQuestions.constants";
import { getWhatsAppLink } from "utils/urlUtils";
import { SwitchField } from "common/Components/TableFields/TableFields";
import { useStyles } from "./tableFields.styles";
import constants from "constants/constants";
import ShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import { BOOKINGS_STATUS_CODES } from "constants/schedule";

export const ViewSlotsField = ({ record = {}, source, showSlots }) => {
  /**
   * Earlier we were checking for slots when records where not empty since we were checking the slots_booked key
   * and in all the cases where there was a record we showed slots
   * reason is we didn't have a flow to book slots after payments
   * Now with this new flow added we need to show N/A where payments have been taken without slots
   * or creator has creator a listing without telling his availability
   * In such cases backend is sending a special session count key for appointment booking flow where either
   * an end user hasnot seleted a slot
   * or a creator isn't sure about his availablity
   * We check session count in such cases and show NA for slots booked
   * we also check if status is refunded or cancelled we are showing NA
   */

  let emptySlotCheck = false;
  if (is_empty(record)) emptySlotCheck = true;
  else if (!record?.sessions_count) emptySlotCheck = true;
  else if (
    [
      BOOKINGS_STATUS_CODES.REFUNDED.id,
      BOOKINGS_STATUS_CODES.CANCELLED.id,
    ].includes(record?.status)
  )
    emptySlotCheck = true; // checking status is refunded/cancelled, i.e. showing N/A, by making "emptySlotCheck = true"

  return (
    <>
      {emptySlotCheck ? (
        <span> N/A </span>
      ) : (
        <span onClick={() => showSlots(record)} className={`action_text`}>
          {`View ${record[source]} slot${record[source] > 1 ? "s" : ""}`}
        </span>
      )}
    </>
  );
};

export const WhatsAppFooterButton = ({
  record = {},
  source,
  countryCodeKey = "country_code",
}) => {
  const onConnectWhatsapp = useCallback(() => {
    const phone_number = parsePhoneNumber(record, {
      countryCodeKey,
      phoneNumberKey: source,
    });
    if (phone_number) {
      window.open(
        `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
        "_blank"
      );
    }
  }, [record, source, countryCodeKey]);

  return (
    <div className="p-3">
      <Button
        color="success"
        fullWidth="true"
        onClick={onConnectWhatsapp}
        className={module_style.WhatsappButton}
      >
        <span>
          <img
            src={require("assets/images/whatsapp.png")}
            height={27}
            width={27}
          />
        </span>
        <span></span>Connect WhatsApp
      </Button>
    </div>
  );
};

export const ScheduleSession = ({ record = {}, listing_data = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirection = () => {
    const query = { uuid: listing_data.uuid, tid: record.transaction_uuid };
    const searchString = qs.stringify(query);
    const parsedData = { ...listing_data };
    parsedData.listing_uuid = listing_data.uuid;
    parsedData.listing_title = listing_data.title;
    if (
      record?.customer_schedule_status ===
      customer_questions_constants.customer_schedule_status.scheduled
    ) {
      dispatch({
        type: EXLY_SCHEDULE__RESCHEDULE_SESSION,
        payload: parsedData,
      });
      const queryparams = {
        uuid: listing_data.uuid,
        tid: record.transaction_uuid,
        slot_uid: record.slot_uuid,
      };
      const searchStringReschedule = qs.stringify(queryparams);
      history.push(`/myschedule/schedule?${searchStringReschedule}`);
    } else if (
      record?.customer_schedule_status ===
        customer_questions_constants.customer_schedule_status.action_required ||
      record?.customer_schedule_status === null
    ) {
      history.push(`/myschedule/schedule?${searchString}`);
    }
  };
  return (
    <span
      onClick={() => redirection()}
      className={`${
        record?.customer_schedule_status !==
          customer_questions_constants.customer_schedule_status.completed &&
        module_style.schedule_text
      }`}
    >
      {record?.customer_schedule_status !==
        customer_questions_constants.customer_schedule_status.completed && (
        <img src={ScheduleNow} alt={"schedule_now"} />
      )}
      {record?.customer_schedule_status ===
      customer_questions_constants.customer_schedule_status.scheduled
        ? "Reschedule Now"
        : record?.customer_schedule_status ===
          customer_questions_constants.customer_schedule_status.completed
        ? "N/A"
        : "Schedule Now"}
    </span>
  );
};

export const NextDueDateValue = ({ record = {}, source }) => {
  const daysDiff = moment().diff(record["subscription_end_date"], "days");
  const isAfter = moment().local().isAfter(record["subscription_end_date"]);
  let formatedString = "";

  if (source === "status") {
    if (daysDiff < 0 && !isAfter) {
      formatedString = "Active";
    } else if (daysDiff >= 0 && daysDiff <= 28) {
      formatedString = "Pending";
    } else {
      formatedString = "In Active";
    }
  } else {
    formatedString = DateConvert(record, "subscription_end_date", true);
  }

  if (daysDiff < 0 && !isAfter) {
    return <span className="text-success">{formatedString}</span>;
  } else if (daysDiff >= 0 && daysDiff <= 28) {
    return <span className="text-danger">{formatedString}</span>;
  } else {
    return <span className="text-warning">{formatedString}</span>;
  }
};

export const WhatsappButton = ({
  record = {},
  source,
  countryCodeKey = "country_code",
}) => {
  const phone_number = parsePhoneNumber(record, {
    countryCodeKey,
    phoneNumberKey: source,
  });

  if (is_empty(record[source])) return "N/A";

  return (
    <Button fullWidth className={module_style.WhatsappButton}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=${phone_number.substring(
          1
        )}`}
      >
        <img
          alt="whatsapp"
          src={require("assets/images/whatsapp.png")}
          height={27}
          width={27}
        />
        Connect WhatsApp
      </a>
    </Button>
  );
};

const downloadReciept = (record) => {
  window.sessionStorage.setItem(
    "customer",
    JSON.stringify({ ...record, phone_number: record.customer_phone })
  );
  window.sessionStorage.setItem("guest__username", record.username);
  window.location.href = `${window.location.origin}/#/CustomerTransactions`;
};

const AnchorComponent = ({ openPopover }) => (
  <span className="pointer" onClick={openPopover}>
    <span className="d-inline-block d-md-none">Options</span>{" "}
    <MoreVert aria-haspopup="true" className={module_style.cursorPointer} />
  </span>
);

export const ShareActions = ({ record = {}, sendEmail }) => {
  const { pushNotification } = useNotifications();

  const copyLink = () => {
    navigator.clipboard.writeText(record.renewal_link);
    pushNotification("Link copied to clipboard", {
      variant: "outlined",
      color: "fine_pine",
    });
  };

  const phone_number = parsePhoneNumber(record, {
    countryCodeKey: "customer_country_code",
    phoneNumberKey: "customer_phone",
  });

  const PopoverComponent = ({ closePopover }) => (
    <div>
      <div
        className={`${module_style.item} ${module_style.noCursor} d-flex justify-content-between`}
      >
        <small className="text-muted">Share/Copy</small>
        <span>
          <FileCopy
            className={`${module_style.cursorPointer} text-dark`}
            onClick={() => {
              copyLink(record);
              closePopover();
            }}
          />{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href={getWhatsAppLink(phone_number, {
              text: record["renewal_link"],
            })}
          >
            <WhatsApp className="text-success" />
          </a>
        </span>
      </div>
      {orgPermissions.canSendEmail() && (
        <div className={module_style.item} onClick={() => sendEmail(record)}>
          <EmailIcon /> Send Custom Email
        </div>
      )}
      <div
        className={module_style.item}
        onClick={() => downloadReciept(record)}
      >
        <ReceiptIcon /> View Transactions
      </div>
    </div>
  );

  return (
    <ExlyPopover
      AnchorComponent={AnchorComponent}
      PopoverComponent={PopoverComponent}
    />
  );
};

//unused component
export const ShippingStatusField = (props) => {
  const { record } = props;
  const classes = useStyles();

  return (
    <SwitchField
      checked={
        parseInt(record.dispatch_status) === constants.dispatchStatus.DISPATCHED
      }
      label={
        <div className={classes.dispatchStatus}>
          <ShippingIcon />
          {constants.product_dispatch_status[record.dispatch_status]?.name}
        </div>
      }
      rootWrapperClassName={
        record.dispatch_status !== constants.dispatchStatus.PENDING &&
        classes.switchRootWrapperClassName
      }
      switchClassName={
        record.dispatch_status !== constants.dispatchStatus.PENDING &&
        classes.switchClassName
      }
      labelClassName={`${
        constants.product_dispatch_status[record.dispatch_status]?.className
      }`}
      {...props}
    />
  );
};
