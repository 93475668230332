import { Grid } from "@material-ui/core";
import React from "react";
import Variant from "../Variant/Variant";
import { useStyles } from "./merchandiseVariants.styles";

const VariantsWrapper = ({ isDesktop, children }) => {
  return isDesktop ? (
    <Grid container spacing={3}>
      {children}
    </Grid>
  ) : (
    children
  );
};

const MerchandiseVariants = ({
  listingData,
  isDesktop,
  variantsData,
  showTopBorder = true,
}) => {
  const classes = useStyles({
    isDesktop,
    hasVariants: !!variantsData,
    showTopBorder,
  });

  return (
    <div className={classes.root}>
      {variantsData ? (
        <VariantsWrapper isDesktop={isDesktop}>
          {variantsData.map((variant, index) => (
            <Variant key={variant.uuid} index={index} {...variant} />
          ))}
        </VariantsWrapper>
      ) : (
        <Variant
          qtyClassName={classes.qty}
          primaryLayout={false}
          {...listingData}
        />
      )}
    </div>
  );
};

export default MerchandiseVariants;
