import SessionStorageConstants from "constants/SessionStorage.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { api } from "data";

export const BATCH_PARTICIPANTS_API = "host/creator/batch/participants";
export const LISTING_PARTICIPANTS_API = "host/creator/listings/participants";

const getPaymentTypeUrl = () =>
  sessionStorage.getItem(
    SessionStorageConstants.IS_SUBSCRIPTION_PAYMENT_TYPE
  ) === "true"
    ? BATCH_PARTICIPANTS_API
    : LISTING_PARTICIPANTS_API;

export const MANAG_BOOKING_RESOURCE_API = {
  [app_route_ids[app_route_keys.bonus_offering_customers]]: () => {
    return {
      api_end_point: api.transaction_list,
      queries: {
        section_name: "bookings",
        community_listing_id: sessionStorage.getItem(
          SessionStorageConstants.LISTING_CUSTOMER_ID
        ),
      },
    };
  },

  [app_route_ids[app_route_keys.subscription_customer]]: () => {
    return {
      api_end_point: getPaymentTypeUrl(),
      queries: {
        listing_uuid: sessionStorage.getItem(
          SessionStorageConstants.LISTING_CUSTOMER_ID
        ),
      },
    };
  },

  [app_route_ids[app_route_keys.listing_customer]]: () => {
    const getPlanQueries = () => {
      const planId = sessionStorage.getItem(SessionStorageConstants.PLAN_ID);
      if (planId) return { plan_uuid: planId };
      else return {};
    };
    return {
      api_end_point: getPaymentTypeUrl(),
      queries: {
        listing_uuid: sessionStorage.getItem(
          SessionStorageConstants.LISTING_CUSTOMER_ID
        ),
        ...getPlanQueries(),
      },
    };
  },
};
