import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  () => ({
    root: {
      display: "flex",
      gap: "16px",
      padding: "12px 0",
      borderTop: ({ isDesktop, showTopBorder }) =>
        isDesktop && showTopBorder && "1.82px solid #E7E6E4",
      borderBottom: ({ isDesktop }) => !isDesktop && "1.82px solid #E7E6E4",
      marginTop: ({ isDesktop }) => isDesktop && "12px",
      flexWrap: ({ isDesktop }) => isDesktop && "wrap",
      overflowX: ({ isDesktop, hasVariants }) =>
        !isDesktop && hasVariants && "scroll",
      maxWidth: ({ isDesktop }) => !isDesktop && "calc(100vw - 42px)",
      "-webkit-overflow-scrolling": "touch",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none" /* IE and Edge */,
      scrollbarWidth: "none",
    },
    qty: {
      margin: 0,
    },
  }),
  { name: "CreatorTool" }
);
