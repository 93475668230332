import {
  EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS,
  EXLY_COMMUNICATIONS__SET_USER_CATEGORY,
  EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY,
  EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS,
  EXLY_COMMUNICATIONS_SET_CERTIFICATE_FORMVALUES,
  EXLY_COMMUNICATIONS_SET_CERTIFICATE_LISTING_TITLE,
  EXLY_COMMUNICATIONS_REQUEST_USER_CERTIFICATES,
  EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES,
  EXLY_COMMUNICATIONS_REQUEST_USER_LISTINGS,
  EXLY_COMMUNICATIONS_SET_USER_LISTINGS,
  EXLY_COMMUNICATIONS_REQUEST_LISTING_DETAILS,
  EXLY_COMMUNICATIONS_SET_LISTING_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_USER_DRAFTS_CERTIFICATES,
  EXLY_COMMUNICATIONS_REQUEST_USER_SHARED_CERTIFICATES,
} from "./actions";

const initialState = {
  user_categories: null,
  creator_categories: null,
  selected_key: null,
  fetching_communications: false,
  certificateData: {},
  listing_title: "",
  user_certificates: null,
  certificate_listing: null,
  listing_data: null,
  page: 1,
};

const reducer = (
  state = initialState,
  { type, payload, key, listing_title, certificateFormValues }
) => {
  switch (type) {
    case EXLY_COMMUNICATIONS__REQUEST_USER_COMMUNICATIONS: {
      return { ...state, selected_key: key, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS__REQUEST_CREATOR_COMMUNICATIONS: {
      return { ...state, selected_key: key, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS__SET_USER_CATEGORY: {
      return {
        ...state,
        user_categories: payload,
        selected_key: null,
        fetching_communications: false,
      };
    }
    case EXLY_COMMUNICATIONS__SET_CREATOR_CATEGORY: {
      return {
        ...state,
        creator_categories: payload,
        selected_key: null,
        fetching_communications: false,
      };
    }
    case EXLY_COMMUNICATIONS_SET_CERTIFICATE_FORMVALUES: {
      return { ...state, certificateData: certificateFormValues };
    }
    case EXLY_COMMUNICATIONS_SET_CERTIFICATE_LISTING_TITLE: {
      return { ...state, listing_title: listing_title };
    }
    case EXLY_COMMUNICATIONS_REQUEST_USER_CERTIFICATES: {
      return { ...state, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS_REQUEST_USER_DRAFTS_CERTIFICATES: {
      return { ...state, page: payload.page, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS_REQUEST_USER_SHARED_CERTIFICATES: {
      return { ...state, page: payload.page, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS_SET_USER_CERTIFICATES: {
      return {
        ...state,
        user_certificates: payload,
        fetching_communications: false,
      };
    }
    case EXLY_COMMUNICATIONS_REQUEST_USER_LISTINGS: {
      return { ...state, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS_SET_USER_LISTINGS: {
      return {
        ...state,
        fetching_communications: false,
        certificate_listing: payload,
      };
    }
    case EXLY_COMMUNICATIONS_REQUEST_LISTING_DETAILS: {
      return { ...state, fetching_communications: true };
    }
    case EXLY_COMMUNICATIONS_SET_LISTING_DETAILS: {
      return {
        ...state,
        fetching_communications: false,
        listing_data: payload,
      };
    }

    case EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS: {
      return { ...state, selected_key: key, fetching_communications: false };
    }

    case EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS: {
      return { ...state, selected_key: key, fetching_communications: false };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
