import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import module_style from "../../Style.module.css";
import constants from "constants/constants";
import { is_empty } from "utils/validations";
import moment from "moment";
import { getUserTimezone } from "utils/AuthUtil";
import style from "../../../../../Style.module.css";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  AppointmentDetails,
  ClassDetails,
  WebinarDetails,
  WorkshopDetails,
} from "../SubscriptionCustomers/Components/TableFields/TableFields";
import MerchandiseVariants from "../ListingCustomers/components/MerchandiseVariants/MerchandiseVariants";

// Function to generate the modal title
const modalTitle = (listing_data) => (
  <div>
    <div style={{ marginBottom: 5, fontWeight: 600, fontSize: 16 }}>
      {listing_data?.title}
    </div>
    <div style={{ marginBottom: 5, fontSize: 14 }}>
      {constants.schedule_type[listing_data?.type]?.name}
    </div>
  </div>
);

// Function to render the slots
export const renderSlots = (form_data, slots_data) => {
  let week_data = [[], [], [], [], [], [], []];

  for (let item of slots_data) {
    const { slot_start_time, slot_end_time } = item || {};

    if (is_empty(slot_start_time)) continue;

    let day = moment(slot_start_time).tz(getUserTimezone()).format("dddd");

    week_data[form_data.week.indexOf(day)].push({
      time_range: [
        moment(slot_start_time).tz(getUserTimezone()).format("hh:mm A"),
        moment(slot_end_time).tz(getUserTimezone()).format("hh:mm A"),
      ],
      id: week_data[form_data.week.indexOf(day)].length + 1,
      date: moment(slot_start_time)
        .tz(getUserTimezone())
        .format(constants.displayDateYearFormat),
    });
  }

  return (
    <>
      <div style={{ marginBottom: 3 }} className={``}>{`Schedule`}</div>
      {week_data
        .filter((item) => !is_empty(item))
        .map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`schedule_${i}`} style={{ marginBottom: 10 }}>
            <div
              style={{ marginBottom: 3 }}
              className={`${style.small_bold_grey}`}
            >
              {form_data.week[week_data.indexOf(item)]}
            </div>
            {item.map((slot) => (
              <div
                key={`slot_${slot.id}`}
                className={`${style.grey_small_text}`}
              >{`Slot ${slot.id} - ${slot.date} ${slot.time_range[0]} to ${slot.time_range[1]}`}</div>
            ))}
          </div>
        ))}
    </>
  );
};

const ListingDetailModal = ({
  listing_data,
  form_data,
  slots_data,
  show_modal,
  set_show_modal,
  set_slots_data,
  listingsCustomersType,
  appointments,
  isMerchandise,
  variantsData,
  isWebinar,
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <>
      <ExlyModal
        className={module_style.moreContentModal}
        open={show_modal}
        title={modalTitle(listing_data)}
        onClose={() => {
          set_show_modal(false);
          set_slots_data(null);
        }}
        modal_props={{
          customFooter: <></>,
          modalPaperClassName: module_style.desktop_detail_modal,
          dialogTitleClassName: module_style.detail_modal_header,
        }}
        mobile_modal_props={{
          customFooter: <></>,
          dialogHeaderClassName: module_style.mobile_detail_header,
        }}
      >
        {
          //TODO: Simplify the below ternary operators
          !is_empty(slots_data) ? (
            renderSlots(form_data, slots_data)
          ) : listingsCustomersType === 3 ? (
            <WorkshopDetails listingData={listing_data} fullWidth noBorder />
          ) : listingsCustomersType === 0 ? (
            <AppointmentDetails
              fullWidth
              appointments={appointments}
              noBorder
            />
          ) : isWebinar ? (
            <WebinarDetails listingData={listing_data} />
          ) : !isMerchandise ? (
            <ClassDetails listingData={listing_data} fullWidth noBorder />
          ) : null
        }
        {isMerchandise && (
          <MerchandiseVariants
            listingData={listing_data}
            isDesktop={isDesktop}
            variantsData={variantsData}
            showTopBorder={false}
          />
        )}
      </ExlyModal>
    </>
  );
};

export default ListingDetailModal;
