//@DEV(muskan) CRM TABLES WILL BE REVAMPED IN R4, THATS WHY NOT MOVING THIS TO FEATURE FOLDER YET.
export const form_data = {
  name: "Name",
  timezone: "Timezone",
  check_answers: "Responses",
  email: "Email",
  phone: "Phone Number",
  booked_on: "Booked On",
  slots_booked: "Slots Booked",
  batch: "Batch",
  clear: "Clear Filter",
  start_date: "Start Date",
  end_date: "End Date",
  subscription_start_date: "Subscription Start Date",
  class_start_date: "Class Start Date",
  next_due_date: "Next Due Date",
  class_end_date: "Class End Date",
  subscription_count: "Number of Renewals",
  creator_sales: "Price Paid",
  class_group_uuid: "Batch",
  subscription_status: "Subscription Status",
  status: "Status",
  OFFERING_VARIATION: "Variation Purchased",
  week: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  course_end_type: ["", "week", "month", "session"],
  type_cta: [""],
  plan_name: "Plan Name",
  payment_debit_mode: "Debit Type",
  variationPurchased: "Variant Purchased",
  price: "Price Paid",
  booked_on_or_after: {
    field: "created_at__start_range",
    placeholder: "Booked On or After",
  },
  min_price: {
    placeholder: "Minimum Price",
    source: "min_price",
  },
  max_price: {
    placeholder: "Maximum Price",
    source: "max_price",
  },
  utm_info: {
    field: "utm_info",
    placeholder: "UTM Info",
  },
  utm_source: {
    source: "utm_source",
    placeholder: "UTM Source",
  },
  utm_medium: {
    field: "utm_info",
    placeholder: "UTM medium",
    source: "utm_medium",
  },
  utm_campaign: {
    field: "utm_info",
    placeholder: "UTM campaign",
    source: "utm_campaign",
  },
};

export const statusFilter = [
  { label: "Active", value: 1 },
  { label: "Due", value: 2 },
  { label: "Overdue", value: 3 },
  { label: "Expired", value: 4 },
];

export const AUTO_DEBIT_STATUS_FILTER = [
  ...statusFilter,
  { label: "Failed", value: 6 },
  { label: "Cancelled", value: 5 },
];

export const OFFERING_VARIANT_KEY = "offering_variant_uuid";

export const subscription_listing_filter_keys = {
  SUBSCRIBED_USER_EMAIL: ({ isNoSchedSub }) =>
    isNoSchedSub
      ? "booked_user__email__icontains"
      : "subscribed_user__email__icontains",
  SUBSCRIBED_USER_PHONE: ({ isNoSchedSub }) =>
    isNoSchedSub
      ? "booked_user__phone_number__icontains"
      : "subscribed_user__phone_number__icontains",
  SUBSCRIPTION_STATUS: "subscription_status",
  OFFERING_VARIATIONS: OFFERING_VARIANT_KEY,
  UPSELL_TYPE: "upsell_type",
  SOURCE_UPSELL_OFFERING_NAME: "source_upsell_offering_name",
  CYCLE_START_DATE: "cycle_start_slot_time__start_range",
  CYCLE_END_DATE: "cycle_end_slot_time__start_range",
  BOOKED_ON: "created_at__start_range",
};

export const UPDATE_CUSTOMER_SUBSCRIPTION_API =
  "host/customer/subscription/update";

export const MIN_RENEWAL_THRESHOLD = 3;
