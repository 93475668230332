import { Grid } from "@material-ui/core";
import { Card } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import React from "react";
import VariationHeader from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/components/Variation/components/VariationHeader";
import { MERCHANDISE_DEFAULT_IMAGE } from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/data";
import {
  getVariantLabel,
  getVariantTags,
} from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/utils";
import { useStyles } from "./variant.styles";

const Variant = ({
  images,
  variant_map: variantMap,
  total_inventory: totalInventory,
  purchased_inventory: purchasedInventory,
  index,
  className,
  qtyClassName,
  primaryLayout = true,
}) => {
  const classes = useStyles();

  const remainingInventory = totalInventory - purchasedInventory;
  const isOverflow = remainingInventory < 0; // this will be true only if creator enabled product purchase overflow

  return (
    <Grid
      item
      className={classnames(classes.root, className)}
      {...(primaryLayout && {
        sm: 6,
        md: 4,
        lg: 4,
        xl: 3,
      })}
    >
      {primaryLayout && (
        <>
          <Card
            variant="outlined"
            shadow="shadow_md"
            className={classes.coverImage}
          >
            <ExlyImage
              src={images[0] || MERCHANDISE_DEFAULT_IMAGE}
              height={68}
              width={68}
            />
          </Card>

          <VariationHeader
            title={getVariantLabel(variantMap, index)}
            options={getVariantTags(variantMap)}
          />
        </>
      )}

      <div className={classnames(classes.qty, qtyClassName)}>
        Stock Quantity {isOverflow ? "Overflow" : "Left"} -{" "}
        <span>{Math.abs(remainingInventory)}</span>
      </div>
    </Grid>
  );
};

export default Variant;
