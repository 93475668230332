import {
  EXLY_SEGMENT_GET_CONDITIONS,
  EXLY_SEGMENT_SET_CONDITIONS,
} from "./actions";
import dataProvider from "data/dataProvider";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { api } from "data";

function* getConditions(data) {
  const state = yield select((state) => state.exlySegment);
  try {
    yield put({
      type: EXLY_SEGMENT_SET_CONDITIONS,
      payload: { ...state },
    });
    const response = yield call(
      dataProvider.custom_request,
      api.segments.get_segment_conditions,
      "GET",
      data.payload
    );
    if (response.status === 200) {
      yield put({
        type: EXLY_SEGMENT_SET_CONDITIONS,
        payload: response.data,
      });
    } else {
      throw `API: ${api.segments.get_segment_conditions} FAIL`;
    }
  } catch (error) {
    console.log("get segment conditions error", error);
    yield put({ type: EXLY_SEGMENT_SET_CONDITIONS, payload: null });
  }
}

function* exlySegmentSaga() {
  yield takeEvery(EXLY_SEGMENT_GET_CONDITIONS, getConditions);
}

export default exlySegmentSaga;
