import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    name_field: ({ isDesktop }) =>
      isDesktop
        ? {
            color: theme?.palette?.primary?.main,
            fontSize: "14px",
            lineHeight: "16px",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }
        : {
            color: theme?.palette?.primary?.main,
            fontSize: "16px",
            lineHeight: "20px",
            textDecoration: "underline",
            fontWeight: 500,
          },
    email_field: ({ isDesktop }) =>
      isDesktop
        ? {
            color: theme?.palette?.primary?.main,
            fontSize: "14px",
            lineHeight: "16px",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }
        : {
            color: theme?.palette?.primary?.main,
            fontSize: "16px",
            lineHeight: "19px",
            textDecoration: "underline",
          },
    desktop_action_field: {
      color: theme?.palette?.primary?.main,
      fontSize: "14px",
      lineHeight: "16px",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    info_field_wrapper: {
      color: theme?.palette?.primary?.main,
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      gap: "20px",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
      "& svg": {
        width: "19.21px",
      },
    },
    tagsWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
      gap: "8px",
      "&:hover": {
        cursor: "pointer",
      },
    },

    tag: {
      background: "#FFEABF",
      padding: "3px 8px",
      borderRadius: "4px",
    },
    more: {
      color: "#493AB1",
    },
  }),
  { name: "CreatorTool" }
);
export default useStyles;
