import React, { useState } from "react";
import moment from "moment";
import classnames from "classnames";

import { api, dataProvider } from "data";
import { Button } from "@my-scoot/component-library-legacy";
import { is_desktop } from "utils/Utils";
import {
  EmailField,
  LinkField,
  WhatsAppButton,
} from "webpage-leads/components/CustomColumns";
import { form_data } from "../../NewCustomers.constants";
import useStyles from "./TableFields.styles";
import { is_empty } from "utils/validations";
import { useNotifications } from "utils/hooks";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import { customer_questions_constants } from "constants/customerQuestions.constants";

export const ReminderButton = ({ record }) => {
  const { notify } = useNotifications();
  const classes = useStyles();
  const [reminding, setReminding] = useState(false);

  const sendReminder = async () => {
    if (reminding) return;
    setReminding(true);
    try {
      const status = await dataProvider.custom_request(
        `${api.send_renewal_reminder}`,
        "POST",
        { transaction_uuid: record.transaction_uuid }
      );
      notify(status.message, "success");
    } catch (err) {
      console.log(err);
      notify(
        err?.body?.message ? err?.body?.message : "Something went wrong",
        "error"
      );
    } finally {
      setReminding(false);
    }
  };
  return is_desktop ? (
    <LinkField linkText="Send Renewal Reminder" onClick={sendReminder} />
  ) : (
    <Button
      startIcon={<NotificationsActiveRoundedIcon />}
      onClick={sendReminder}
      disabled={reminding}
      fullWidth
    >
      <div className={classes.flexGrow}>Send Renewal Reminder</div>
    </Button>
  );
};

export const ShareField = ({ record, isGroupClass }) => {
  const whatsapp = (
    <WhatsAppButton
      record={record}
      source="customer_phone"
      countryCodeKey="customer_country_code"
    />
  );

  const email = (
    <EmailField showIconOnly record={record} source="customer_email" />
  );

  return isGroupClass ? (
    <div>
      <span className="mr-2">{email}</span>
      {whatsapp}
    </div>
  ) : (
    email
  );
};

export const ClassDetails = ({ listingData, fullWidth, noBorder }) => {
  const classes = useStyles({ fullWidth, noBorder });
  if (is_empty(listingData)) return "N/A";
  return (
    <div className={classes.classDetailsWrap}>
      <div className={`font_500 mb-2 mt-2`}>
        {`Schedule for ${listingData?.course_end_magnitude} ${
          form_data.course_end_type[listingData.course_end_choice]
        }`}
      </div>
      {listingData?.request_groups?.map((batch, batchIndex) => (
        <div
          className={classes.batch}
          //Have to do it intentionally as no unique id present
          // eslint-disable-next-line react/no-array-index-key
          key={`${batch.duration}__${batchIndex}_batch`}
        >
          <div className={``}>
            <span className="font_600">Batch {batchIndex + 1}</span>
            {` (Each session of ${listingData.duration} minutes)`}
          </div>
          {batch?.map((session) => (
            <div
              className={classes.session}
              key={`session_${session.start_time}`}
            >
              {`${form_data.week[session.interval]}: Starting from ${
                session.start_time
              }`}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const WorkshopDetails = ({ listingData, fullWidth, noBorder }) => {
  const classes = useStyles({ fullWidth, noBorder });
  if (is_empty(listingData)) return "N/A";
  return (
    <div
      className={classnames(
        classes.classDetailsWrap,
        classes.workshopDetailsWrap
      )}
    >
      <div className={`mt-2`}>
        {!is_empty(listingData?.request_groups[0]?.length) &&
          `Schedule (${listingData?.request_groups[0].length} Session)`}
      </div>
      {listingData?.request_groups[0].map((batch, batchIndex) => (
        <div className={classes.batch} key={`${batch.duration}__batch`}>
          <span>
            <span className="font_600">Session {batchIndex + 1}</span>
            {` - ${moment(`${batch.interval} ${batch.start_time}`, [
              "YYYY-MM-DD hh:mm A",
            ]).format("dddd MMM DD - hh:mm A to ")}${moment(
              moment(batch.start_time, ["hh:mm A"]).add(
                listingData.duration,
                "minutes"
              )
            ).format("hh:mm A")}`}
          </span>
        </div>
      ))}
    </div>
  );
};

export const AppointmentDetails = ({ appointments, fullWidth, noBorder }) => {
  const classes = useStyles({ fullWidth, noBorder });
  if (is_empty(appointments)) return null;

  return (
    <div
      className={classnames(
        classes.classDetailsWrap,
        classes.workshopDetailsWrap
      )}
    >
      {appointments?.map((slot, slotIndex) => (
        //Have to do it intentionally as no unique id present
        // eslint-disable-next-line react/no-array-index-key
        <div key={`app_${slot.date}_${slotIndex}`} className={classes.batch}>
          <span className="font_500">{slot.date}</span>
          <div>
            {slot.slots.map((item, idx, arr) => (
              <>
                <span>{item}</span>
                {idx < arr.length - 1 && <span>{", "}</span>}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const WebinarDetails = ({ listingData, fullWidth, noBorder }) => {
  const classes = useStyles({ fullWidth, noBorder });
  if (is_empty(listingData)) return "N/A";
  return (
    <div className={classes.classDetailsWrap}>
      <div className={`font_500 mb-2 mt-2`}>{`Schedule`}</div>
      {listingData.request_groups.map((batch) => (
        <div className={classes.batchColumn} key={`${batch.duration}__batch`}>
          {listingData.availability_choice !==
          customer_questions_constants.avaibility_type
            .occur_on_selected_days_of_week ? (
            <>
              {batch.map((session, sessionIndex) => (
                <div
                  className={classes.session}
                  key={`session__${session.start_time}`}
                >
                  <span className="font_600">
                    Session {sessionIndex + 1} :{" "}
                  </span>{" "}
                  {`${session.interval} , ${session.start_time}`}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};
