import {
  EXLY_SEGMENT_RESET_SEGMENT,
  EXLY_SEGMENT_SET_CONDITIONS,
  EXLY_SEGMENT_SET_NAME,
  EXLY_SEGMENT_TOGGLE_COMBINING_FILTER,
  EXLY_SEGMENT_UPDATE_CONDITIONS,
} from "./actions";

const initialState = {
  conditions: [],
  combining_filter: "AND",
  title: "",
  target: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_SEGMENT_SET_CONDITIONS: {
      return {
        ...state,
        ...payload,
      };
    }

    case EXLY_SEGMENT_TOGGLE_COMBINING_FILTER: {
      return {
        ...state,
        combining_filter: payload.combining_filter,
      };
    }

    case EXLY_SEGMENT_UPDATE_CONDITIONS: {
      return {
        ...state,
        conditions: payload.conditions,
      };
    }

    case EXLY_SEGMENT_SET_NAME: {
      return {
        ...state,
        title: payload.title,
      };
    }

    case EXLY_SEGMENT_RESET_SEGMENT: {
      return {
        conditions: [],
        combining_filter: "AND",
        title: "",
        target: "",
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
