export const sub_category = {
  lead_intent: [
    { title: "High Intent", filter: "lead_intent", value: 1 },
    { title: "Low Intent", filter: "lead_intent", value: 3 },
  ],
  new_leads: [
    { title: "Captured in last 7 days", filter: "days", value: 7 },
    { title: "Captured in last 30 days", filter: "days", value: 30 },
  ],
  // lead_source: [{ title: 'Requested to be contacted', filter: 'reason_type', value: 20 }, { title: 'Didn’t complete payment', filter: 'reason_type', value: 5 }],
  lead_source: [
    { title: "Interested in an offering", filter: "reason_type", value: 0 },
    { title: "Payment Failed", filter: "reason_type", value: 4 },
    { title: "Payment Initiated", filter: "reason_type", value: 5 },
    { title: "Requested to be contacted", filter: "reason_type", value: 1 },
    { title: "Viewed an offering", filter: "reason_type", value: 2 },
    { title: "Viewed your website", filter: "reason_type", value: 3 },
    { title: "Imported leads", filter: "reason_type", value: 8 },
  ],
};

export const ALL = "all";
export const filterTypesMapping = {
  select: "select",
};
