import { ReactComponent as PeopleIcon } from "assets/vectors/upsellCards/total_customers_blue.svg";
import { ReactComponent as AttendeesIcon } from "assets/vectors/upsellCards/total_attendees.svg";
import { ReactComponent as LoyalCustomers } from "assets/vectors/upsellCards/care.svg";
import { ReactComponent as LifetimeValue } from "assets/vectors/upsellCards/lifetime_value.svg";
import { ReactComponent as SmileyBlue } from "assets/vectors/upsellCards/smiley_blue.svg";
import { ReactComponent as BriefcaseYellow } from "assets/vectors/upsellCards/briefcase_yellow.svg";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { is_empty } from "utils/validations";
import { isSubscriptionPaymentType } from "utils/listing/listingUtils";

// Enums
export const LISTING_CUSTOMERS = "Bookings";
export const PROMOTIONS_CUSTOMERS = "Promotions";
export const ORDER_BUMPS = "Order Bumps";
export const THANKYOU_PAGE = "Thank You Page";
export const PROMOTION_FROM_OFFERING = "Promotion From Offering";
export const UPSELL = "Upsell";
export const BRANDED_COMMUNITY = "Branded Community";

export const upsellTypeMap = {
  1: ORDER_BUMPS,
  2: THANKYOU_PAGE,
  3: UPSELL,
  4: BRANDED_COMMUNITY,
};

export const listing_api_keys = {
  NAME: "guest_name",
  SUBSCRIBED_USER_EMAIL: "subscribed_user__email__icontains",
  USER_EMAIL: "user__email__icontains",
  SUBSCRIBED_USER_PHONE: "subscribed_user__phone_number__icontains",
  USER_PHONE: "user__phone_number__icontains",
  TRANSACTION_START_DATE: "transaction__created_at__start_range",
  START_DATE: "created_at__start_range",
  TRANSACTION_END_DATE: "transaction__created_at__end_range",
  END_DATE: "created_at__end_range",
  VARIANT: "variant_uuid",
  UPSELL_TYPE: "upsell_type",
  OFFERING_PURCHASED: "purchased_offering_name",
  PURCHASED_FROM_OFFERING: "source_upsell_offering_name",
};

export const PARTICIPANT_DATA_KEYS = {
  answer_count: "answer_count",
  booked_on: "booked_on",
  customer_country_code: "customer_country_code",
  customer_email: "customer_email",
  customer_name: "customer_name",
  customer_phone: "customer_phone",
  customer_timezone: "customer_timezone",
  listing_id: "listing_id",
  purchased_offering_name: "purchased_offering_name",
  quantity: "quantity",
  shipping_cost: "shipping_cost",
  slots_booked: "slots_booked",
  status: "status",
  transaction_id: "transaction_id",
  transaction_uuid: "transaction_uuid",
  upsell_type: "upsell_type",
  username: "username",
  all_installments_paid: "all_installments_paid",
};

export const ADD_CUSTOMER_API_PAYLOAD_KEYS = {
  full_name: "full_name",
  email: "email",
  phone: "phone",
  country_code: "country_code",
  country: "country",
  listing_uid: "listing_uid",
  quantity: "quantity",
  currency: "currency",
  customer_timezone: "customer_timezone",
  send_comms: "send_comms",
};

export const ADD_CUSTOMER_INSTALMENT_PAYMENT_API_PAYLOAD = {
  transaction_uuid: "transaction_uuid",
  send_installment_comms: "send_installment_comms",
  installments: "installments",
  username: "username",
};

export const change_message_initial_state = {
  status_change_message: "",
};

export const tabsConfig = [
  {
    label: LISTING_CUSTOMERS,
    value: LISTING_CUSTOMERS,
  },
  {
    label: PROMOTIONS_CUSTOMERS,
    value: PROMOTIONS_CUSTOMERS,
  },
];

export const promotional_upsells = {
  upsell_type: "Promotion Type",
  source_upsell_offering_title: "Offering Purchased",
  source_upsell_offering_name: "Promotion From Offering",
};

export const upsellCardTitles = {
  new_customers: "New Customers",
  total_customers: "Total Customers",
  loyal_customers: "Loyal Customers",
  upsells: "Upsells",
  total_attendees: "Total Attendees",
  lifetime_value: "Lifetime Value",
};

export const cardRoutes = {
  [upsellCardTitles.upsells]: app_route_ids[app_route_keys.upsell_details],
  [upsellCardTitles.total_attendees]:
    app_route_ids[app_route_keys.attendees_details],
  [upsellCardTitles.multiple_purchases]:
    app_route_ids[app_route_keys.multiple_purchases_details],
};

export const upsellMapping = {
  total_customers: {
    Icon: PeopleIcon,
    onClick: () => {},

    statsNumber: 120,
    className: "",
    title: upsellCardTitles.total_customers,
    color: "primary",
    tooltipString: "Number of people who bought your offering",
  },
  total_attendees: {
    Icon: AttendeesIcon,
    onClick: () => {
      window.location.href = `${window.location.origin}/#/${
        cardRoutes[upsellCardTitles.total_attendees]
      }`;
    },
    statsNumber: 120,
    className: "",
    title: upsellCardTitles.total_attendees,
    color: "fine_pine",
    tooltipString: "Number of customers who attended your offering",
  },

  total_new_customers: {
    Icon: SmileyBlue,
    onClick: () => {},
    statsNumber: 120,
    className: "",
    title: upsellCardTitles.new_customers,
    color: "persian_blue",
    tooltipString: "Number of people whose first offering is this offering",
  },

  total_upsells: {
    Icon: BriefcaseYellow,
    onClick: () => {
      window.location.href = `${window.location.origin}/#/${
        cardRoutes[upsellCardTitles.upsells]
      }`;
    },
    statsNumber: 120,
    className: "",
    title: upsellCardTitles.upsells,
    color: "butterscotch_yellow",
    tooltipString: `Number of new customers who bought the offering that you
     were upselling through order bumps, thank you page, or live events`,
  },
  total_loyal_customers: {
    Icon: LoyalCustomers,
    onClick: () => {
      window.location.href = `${window.location.origin}/#/${
        cardRoutes[upsellCardTitles.multiple_purchases]
      }`;
    },
    statsNumber: 120,
    className: "",
    title: upsellCardTitles.loyal_customers,
    color: "fine_pine",
    tooltipString:
      "Number of new customers of this offering who have bought any other offering",
  },
  total_lifetime_value: {
    key: "total_lifetime_value",
    Icon: LifetimeValue,
    onClick: () => {},
    statsNumber: 120,
    className: "",
    title: upsellCardTitles.lifetime_value,
    color: "persian_blue",
    tooltipString:
      "The total amount new customers of this offering have spent on all your offerings",
  },
};

export const COMMUNITY_TABS = {
  COMMUNITY: {
    label: "Community",
    value: "community",
  },
  BONUS_OFFERINSG: {
    label: "Bonus Offering",
    value: "bonus_offering",
    path: app_route_ids[app_route_keys.bonus_offering_customers],
  },
};

export const COMMUNITY_TABS_CONFIG = (listing_data) => {
  if (is_empty(listing_data)) return null;
  const isSubscription = isSubscriptionPaymentType(listing_data);
  return [
    {
      label: COMMUNITY_TABS.COMMUNITY.label,
      value: COMMUNITY_TABS.COMMUNITY.value,
      path: isSubscription
        ? app_route_ids[app_route_keys.subscription_customer]
        : app_route_ids[app_route_keys.listing_customer],
    },
    {
      label: COMMUNITY_TABS.BONUS_OFFERINSG.label,
      value: COMMUNITY_TABS.BONUS_OFFERINSG.value,
      path: `/${COMMUNITY_TABS.BONUS_OFFERINSG.path}`,
    },
  ];
};

export const DEFAULT_UPSELLING_STATS_NUMBER = 0;

export const total_lifetime_value_key = "total_lifetime_value";

export const FORM_DATA = {
  subs_renewal: "Subscription Renewal",
  status: "Status",
};

export const FIELD_DATA = {
  status: "status",
};

export const MANAGE_BOOKINGS_LEARN_MORE_HREFS = {
  MANAGE_BOOKINGS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392256-how-to-manage-your-bookings-on-exly-",
  MANAGE_BOOKINGS_VIDEO: "https://www.youtube-nocookie.com/embed/bIEVwboA334",
};
