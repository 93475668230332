import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  switchRootWrapperClassName: {
    opacity: 0.5,
    cursor: "not-allowed",
    "&:hover": {
      borderColor: "transparent",
    },
  },
  switchClassName: {
    pointerEvents: "none",
  },
  dispatchStatus: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));
